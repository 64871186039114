<template>
    <div class="pb-4" v-if="visible">
        <v-alert
            v-model="visible"
            key="loginPageError"
            type="error"
            width="auto"
            dismissible
        >
            {{ error }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'LoginPageError',
    data: () => ({
        visible: false,
    }),
    watch: {
        error: function (value) {
            this.visible = !!value;
        },
    },
    created() {
        if (this.error) {
            this.visible = true;
        }
    },
    props: {
        error: {
            type: String,
            default: '',
        },
    },
};
</script>
