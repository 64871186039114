<template>
    <v-container v-bind:class="{ 'fill-height': !mobile }">
        <v-row align="center" justify="center" dense>
            <!--elevation-12-->
            <v-card
                v-bind:class="{
                    'elevation-0': mobile,
                    'elevation-12': !mobile,
                }"
                :loading="!mobile && busy"
                :max-width="maxWidth"
                :min-width="minWidth"
                :width="width"
                :disabled="busy"
            >
                <SpinnerOverlay v-if="mobile" v-show="busy" />

                <v-container
                    key="publicForm"
                    v-bind:class="{
                        'pa-0': mobile,
                    }"
                >
                    <transition name="fade">
                        <slot />
                    </transition>
                </v-container>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import SpinnerOverlay from '../../../components/pieces/Global/SpinnerOverlay';
export default {
    name: 'PublicForm',
    data: () => ({
        mobile: innerWidth <= 500,
    }),
    methods: {},
    props: {
        maxWidth: {
            type: String,
            default: '450',
        },
        minWidth: {
            type: String,
            default: '350',
        },
        width: {
            type: String,
            default: '450',
        },
        busy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        theme: function () {
            if (this.$vuetify.theme.dark) {
                return 'dark';
            } else {
                return 'light';
            }
        },
    },
    components: {
        SpinnerOverlay,
    },
    mounted() {
        document.title = this.$route.meta.title + " - Breeze Connect" || "Breeze Connect";
    },
    created() {
        addEventListener('resize', () => {
            this.mobile = innerWidth <= 500;
        });
    },
};
</script>
